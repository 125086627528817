import {
    BrowserRouter,
    MemoryRouter,
    Navigate,
    Route,
    Routes,
} from 'react-router-dom';
import type { InitialEntry, Location } from 'history';
import { createContext, memo, useContext } from 'react';

import Accessibility from './pages/Accessibility/Accessibility';
import { Attachments } from './pages/Dashboard/pages/Attachments/Attachments';
import Auth from './pages/Auth/Auth';
import ClientAdministration from './pages/Dashboard/pages/ClientAdministration/ClientAdministration';
import Dashboard from './pages/Dashboard/Dashboard';
import EasyLanguagePage from './pages/EasyLanguagePage/EasyLanguagePage';
import Feedback from './pages/Dashboard/pages/Feedback/Feedback';
import { Help } from './pages/Help/Help';
import Home from './pages/Dashboard/pages/Home/Home';
import Imprint from './pages/Imprint/Imprint';
import LegalNotice from './pages/Dashboard/pages/LegalNotice/LegalNotice';
import Login from './pages/Auth/pages/Login/Login';
import { Messages } from './pages/Dashboard/pages/Messages/Messages';
import { Page } from './shared/components/global/Page/Page';
import { PageWrapper } from './shared/components/global/PageWrapper/PageWrapper';
import PasswordForgotten from './pages/Auth/pages/PasswordForgotten/PasswordForgotten';
import PasswordReset from './pages/Auth/pages/PasswordReset/PasswordReset';
import PrivacyPolicy from './pages/PrivacyPolicy/PrivacyPolicy';
import Register from './pages/Auth/pages/Register/Register';
import RegistrationConfirm from './pages/Auth/pages/RegistrationConfirm/RegistrationConfirm';
import Settings from './pages/Dashboard/pages/Settings/Settings';
import SignLanguagePage from './pages/SignLanguagePage/SignLanguagePage';
import TermsOfUse from './pages/TermsOfUse/TermsOfUse';
import { UserAdministration } from './pages/Dashboard/pages/UserAdministration/UserAdministration';
import { XRechnungPage } from './pages/Dashboard/pages/XRechnungPage/XRechnungPage';
import { useTranslation } from 'react-i18next';

type AppRouterContextData = {
    isInTestMode: boolean;
};

export const AppRouterContext = createContext<AppRouterContextData | null>(
    null
);

export const useAppRouterContext = () => useContext(AppRouterContext);

export interface IAppRouter {
    isLoggedIn: boolean;
    cookies: any;
    basename: string | null;
    isInTestMode?: boolean;
    appTitleShortcut?: string;
    location?: string | Partial<Location>;
    testInitialEntries?: InitialEntry[];
}

export default function AppRouter({
    isInTestMode = false,
    ...props
}: IAppRouter) {
    const { t } = useTranslation();
    let genericRoutes = (
        <>
            <Route
                path='imprint'
                element={
                    <Page
                        isInTestMode={isInTestMode}
                        i18title='Common.Imprint.Text'
                        component={<Imprint />}
                        appTitleShortcut={props.appTitleShortcut}
                    />
                }
            />
            <Route
                path='privacy-policy'
                element={
                    <Page
                        isInTestMode={isInTestMode}
                        i18title='Common.DataProtection.Text'
                        component={<PrivacyPolicy />}
                        appTitleShortcut={props.appTitleShortcut}
                    />
                }
            />
            <Route
                path='terms-of-use'
                element={
                    <Page
                        isInTestMode={isInTestMode}
                        i18title='Common.TermsOfUse.Text'
                        component={<TermsOfUse />}
                        appTitleShortcut={props.appTitleShortcut}
                    />
                }
            />
            <Route
                path='accessibility'
                element={
                    <Page
                        isInTestMode={isInTestMode}
                        i18title='Common.DeclarationAccessibility.Text'
                        component={<Accessibility />}
                        appTitleShortcut={props.appTitleShortcut}
                    />
                }
            />
            <Route
                path='help'
                element={
                    <Page
                        isInTestMode={isInTestMode}
                        i18title='Common.Help.Text'
                        component={<Help />}
                        appTitleShortcut={props.appTitleShortcut}
                    />
                }
            />
            <Route
                path='legal-notice'
                element={
                    <Page
                        isInTestMode={isInTestMode}
                        i18title='Common.LegalNotice.Text'
                        component={<LegalNotice />}
                        appTitleShortcut={props.appTitleShortcut}
                    />
                }
            />
            <Route path='feedback'>
                <Route path='' element={<Navigate to='report-barrier' />} />
                <Route
                    path='report-barrier'
                    element={
                        <Page
                            isInTestMode={isInTestMode}
                            i18title='Common.ReportBarrier.Text'
                            component={
                                <Feedback
                                    subject={t('Common.ReportBarrier.Text')}
                                />
                            }
                            appTitleShortcut={props.appTitleShortcut}
                        />
                    }
                />
                <Route path='*' element={<Navigate to='report-barrier' />} />
            </Route>

            <Route
                path='sign-language'
                element={
                    <Page
                        isInTestMode={isInTestMode}
                        i18title='Common.SignLanguage.Text'
                        component={
                            <SignLanguagePage i18title='Common.SignLanguage.Text' />
                        }
                        appTitleShortcut={props.appTitleShortcut}
                    />
                }
            />

            <Route
                path='easy-language'
                element={
                    <Page
                        isInTestMode={isInTestMode}
                        i18title='Common.EasyLanguage.Text'
                        component={
                            <EasyLanguagePage i18title='Common.EasyLanguage.Text' />
                        }
                        appTitleShortcut={props.appTitleShortcut}
                    />
                }
            />
        </>
    );

    let authRoutes = (
        <>
            <Route index element={<Navigate to='login' />} />

            <Route
                path='login'
                element={
                    <Page
                        isInTestMode={isInTestMode}
                        i18title='Login.LoginCard.Text'
                        component={<Login />}
                        appTitleShortcut={props.appTitleShortcut}
                    />
                }
            />

            <Route
                path='register'
                element={
                    <Page
                        isInTestMode={isInTestMode}
                        i18title='Registration.RegistrationCard.Text'
                        component={<Register />}
                        appTitleShortcut={props.appTitleShortcut}
                    />
                }
            />

            <Route
                path='registration-confirm/:token'
                element={
                    <Page
                        isInTestMode={isInTestMode}
                        i18title='Registration.ConfirmationCard.Text'
                        component={<RegistrationConfirm />}
                        appTitleShortcut={props.appTitleShortcut}
                    />
                }
            />

            <Route
                path='password-forgotten'
                element={
                    <Page
                        isInTestMode={isInTestMode}
                        i18title='PasswordForgotten.FormTitle.Text'
                        component={<PasswordForgotten />}
                        appTitleShortcut={props.appTitleShortcut}
                    />
                }
            />

            <Route
                path='password-reset/:token'
                element={
                    <Page
                        isInTestMode={isInTestMode}
                        i18title='ResetPassword.FormTitle.Text'
                        component={<PasswordReset becauseExpired={false} />}
                        appTitleShortcut={props.appTitleShortcut}
                    />
                }
            />

            <Route
                path='reset-expired-password/:token'
                element={
                    <Page
                        isInTestMode={isInTestMode}
                        i18title='ResetPassword.FormTitle.Text'
                        component={<PasswordReset becauseExpired={true} />}
                        appTitleShortcut={props.appTitleShortcut}
                    />
                }
            />
        </>
    );

    let dashboardRoutes = (
        <>
            <Route index element={<Navigate to='home' />} />

            <Route
                path='home'
                element={
                    <Page
                        isInTestMode={isInTestMode}
                        i18title='Common.Home.Text'
                        component={<Home />}
                        appTitleShortcut={props.appTitleShortcut}
                    />
                }
            />

            <Route
                path='messages'
                element={
                    <Page
                        isInTestMode={isInTestMode}
                        i18title='Common.Messages.Text'
                        component={<Messages />}
                        appTitleShortcut={props.appTitleShortcut}
                    />
                }
            />

            <Route
                path='attachments'
                element={
                    <Page
                        isInTestMode={isInTestMode}
                        i18title='Common.BigAttachments.Text'
                        component={<Attachments />}
                        appTitleShortcut={props.appTitleShortcut}
                    />
                }
            />

            <Route
                path='user-administration'
                element={
                    <Page
                        isInTestMode={isInTestMode}
                        i18title='Common.UserAdministration.Text'
                        component={<UserAdministration />}
                        appTitleShortcut={props.appTitleShortcut}
                    />
                }
            />

            <Route
                path='client-administration'
                element={
                    <Page
                        isInTestMode={isInTestMode}
                        i18title='Common.ClientAdministration.Text'
                        component={<ClientAdministration />}
                        appTitleShortcut={props.appTitleShortcut}
                    />
                }
            />

            <Route
                path='xrechnung/:id'
                element={
                    <Page
                        isInTestMode={isInTestMode}
                        i18title='Common.XRechnung.Text'
                        component={<XRechnungPage />}
                        appTitleShortcut={props.appTitleShortcut}
                    />
                }
            />

            <Route
                path='xrechnung/:id/:tab'
                element={
                    <Page
                        isInTestMode={isInTestMode}
                        i18title='Common.XRechnung.Text'
                        component={<XRechnungPage />}
                        appTitleShortcut={props.appTitleShortcut}
                    />
                }
            />

            <Route
                path='settings'
                element={
                    <Page
                        isInTestMode={isInTestMode}
                        i18title='Common.PersonalSettings.Text'
                        component={<Settings />}
                        appTitleShortcut={props.appTitleShortcut}
                    />
                }
            />

            <Route path='*' element={<Navigate to='/dashboard' />} />
        </>
    );

    let allRoutes = (
        <Routes>
            <Route path='/'>
                <Route
                    index
                    element={
                        <Navigate
                            to={
                                props.isLoggedIn
                                    ? props.cookies['dashboard-route'] ??
                                      'dashboard'
                                    : 'auth'
                            }
                        />
                    }
                />

                <Route
                    path='auth'
                    element={<PageWrapper component={<Auth />} />}
                >
                    {authRoutes}
                    {genericRoutes}
                </Route>

                <Route
                    path='dashboard'
                    element={<PageWrapper component={<Dashboard />} />}
                >
                    {dashboardRoutes}
                    {genericRoutes}
                </Route>
            </Route>

            <Route path='*' element={<Navigate to='/' />} />
        </Routes>
    );

    return (
        <AppRouterContext.Provider value={{ isInTestMode: isInTestMode }}>
            {props.basename !== null && (
                <>
                    {(!isInTestMode && (
                        <BrowserRouter basename={props.basename}>
                            {allRoutes}
                        </BrowserRouter>
                    )) || (
                        <MemoryRouter
                            basename={props.basename}
                            initialEntries={props.testInitialEntries}
                        >
                            {allRoutes}
                        </MemoryRouter>
                    )}
                </>
            )}
        </AppRouterContext.Provider>
    );
}

export const MemorizedAppRouter = memo(AppRouter);
