import { RequestTools } from '@b4valuenet/ui-react';
import { Attachment } from '../models/Attachment';
import { GenericActionResponse } from '../models/GenericActionResponse';
import { InvoiceValidationResponse } from '../models/InvoiceValidationResponse';

export default class UploadService {
    static ApiRoot = 'api/upload/';

    static validate = async (
        file: File,
        abortController: AbortController,
        onUploadProgress: CallableFunction
    ): Promise<InvoiceValidationResponse | null> => {
        let formData = new FormData();

        formData.append('file', file);

        let response = await RequestTools.post(
            this.ApiRoot + 'validate',
            formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                signal: abortController.signal,
                onUploadProgress: (progressEvent: ProgressEvent) => {
                    onUploadProgress(progressEvent);
                },
            }
        );

        let json = await response.data;
        let result = (json as InvoiceValidationResponse) || null;

        return result;
    };

    static submit = async (
        file: File,
        abortController: AbortController,
        onUploadProgress: CallableFunction
    ): Promise<GenericActionResponse | null> => {
        let formData = new FormData();

        formData.append('file', file);

        let response = await RequestTools.post(
            this.ApiRoot + 'file',
            formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                signal: abortController.signal,
                onUploadProgress: (progressEvent: ProgressEvent) => {
                    onUploadProgress(progressEvent);
                },
            }
        );

        let json = await response.data;
        let result = (json as GenericActionResponse) || null;

        return result;
    };

    static uploadAttachment = async (
        file: File,
        messageId: number,
        abortController: AbortController,
        onUploadProgress: CallableFunction
    ): Promise<Attachment | null> => {
        let formData = new FormData();

        formData.append('file', file);

        let response = await RequestTools.post(
            this.ApiRoot + 'attachment?messageId=' + messageId,
            formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                signal: abortController.signal,
                onUploadProgress: (progressEvent: ProgressEvent) => {
                    onUploadProgress(progressEvent);
                },
            }
        );

        let json = await response.data;
        let result = (json.data as Attachment) || null;

        return result;
    };

    static uploadInvoiceTemplate = async (
        file: File
        // abortController: AbortController,
        // onUploadProgress: CallableFunction
    ): Promise<number | null> => {
        let formData = new FormData();

        formData.append('file', file);

        let response = await RequestTools.post(
            this.ApiRoot + 'extractfile',
            formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                // signal: abortController.signal,
                // onUploadProgress: (progressEvent: ProgressEvent) => {
                //     onUploadProgress(progressEvent);
                // },
            }
        );

        let json = await response.data;
        let result = (json.data as number) || null;

        return result;
    };
}
