import { RequestTools, SortState } from '@b4valuenet/ui-react';

import { BigAttachment } from '../models/BigAttachment';
import { BigAttachmentUploadRestrictions } from '../models/BigAttachmentUploadRestrictions';
import { GenericActionResponse } from '../models/GenericActionResponse';
import { GetBigAttachmentsResponse } from '../models/GetBigAttachmentsResponse';
import { UploadBigAttachmentResponse } from '../models/UploadBigAttachmentResponse';

export default class BigAttachmentsService {
    static ApiRoot = 'api/';
    static OdataRoot = 'api/';

    static getBigAttachments = async (
        currentPage: number,
        itemsProPage: number,
        activeSort: SortState,
        activeSortProperty: string | null,
        filteringString: string | null = ''
    ): Promise<GetBigAttachmentsResponse | null> => {
        let skip = (currentPage - 1) * itemsProPage;

        filteringString = filteringString ?? '';

        let response = await RequestTools.get(
            this.OdataRoot + 'BigAttachmentView',
            {
                params: {
                    $skip: skip,
                    $top: itemsProPage,
                    $orderby:
                        activeSort !== SortState.None &&
                        activeSortProperty !== null
                            ? activeSortProperty +
                              ' ' +
                              (activeSort === SortState.Asc ? 'asc' : 'desc')
                            : 'metaData/created desc',
                    $count: true,
                    $filter:
                        filteringString.length > 0
                            ? filteringString
                            : undefined,
                },
            }
        );

        let json = await response.data;
        let result = (json as GetBigAttachmentsResponse) || null;

        return result;
    };

    static getUploadRestrictions =
        async (): Promise<BigAttachmentUploadRestrictions | null> => {
            let response = await RequestTools.get(
                this.ApiRoot + 'bigAttachment/uploadRestrictions'
            );

            let json = await response.data;
            let result = (json.data as BigAttachmentUploadRestrictions) || null;

            if (result !== null) {
                result.allowedFileExtensions = result.allowedFileExtensions
                    .join(', ')
                    .replaceAll('.', '')
                    .split(', ');
            }

            return result ?? null;
        };

    static upload = async (
        file: File,
        abortController: AbortController,
        onUploadProgress: CallableFunction
    ): Promise<UploadBigAttachmentResponse | null> => {
        let formData = new FormData();

        formData.append('file', file);

        let response = await RequestTools.post(
            this.ApiRoot + 'bigAttachment',
            formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                signal: abortController.signal,
                onUploadProgress: (progressEvent: ProgressEvent) => {
                    onUploadProgress(progressEvent);
                },
            }
        );

        let result = (response.data as UploadBigAttachmentResponse) || null;

        return result;
    };

    static download = async (attachment: BigAttachment) => {
        await RequestTools.get(
            this.ApiRoot + 'bigAttachment/' + attachment.id,
            {
                params: {
                    hash: attachment.metaData.hash,
                },
                responseType: 'blob', // important
            }
        ).then((response) => {
            let headerLine = response.headers['content-disposition'] ?? null;

            if (headerLine !== null) {
                let filename = '';

                let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                let matches = filenameRegex.exec(headerLine);
                if (matches != null && matches[1]) {
                    filename = matches[1].replace(/['"]/g, '');
                }

                let url = window.URL.createObjectURL(new Blob([response.data]));
                let link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', filename);
                document.body.appendChild(link);
                link.click();
            }
        });
    };

    static extendExpiration = async (
        guid: string
    ): Promise<GenericActionResponse | null> => {
        let response = await RequestTools.patch(
            this.ApiRoot + 'bigAttachment/' + guid
        );

        let json = await response.data;
        let result = (json as GenericActionResponse) || null;

        return result;
    };

    static delete = async (
        guid: string
    ): Promise<GenericActionResponse | null> => {
        let response = await RequestTools.delete(
            this.ApiRoot + 'bigAttachment/' + guid
        );

        let json = await response.data;
        let result = (json as GenericActionResponse) || null;

        return result;
    };
}
